import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import List from '../components/Common/List'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const WhatIsBim: React.FC = () => {
  return (
    <>
      <SEO
        title="What is BIM"
        description="Building information modeling (BIM) is a model-based technology linked with a project information database."
        keywords="BIM, Building Information Modeling, 3D Model, BIM software, BIM benefits, Cost control, Clash detection"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          What is BIM
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={680}
            src="../assets/images/articles/what-is-bim/preview.jpg"
            alt="Building information modeling"
            title="Building information modeling"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          There was a drawing board and a pencil at first. Subsequently,
          Computer-Aided Design (CAD) emerged, wherein drawings were created
          using identical linear tools but executed on a computer. Builders
          still worked with paper, and project managers managed the project with
          the help of 2D drawings only. Then came BIM, which marked the
          transformation from traditional 2D layouts to a 3D information space,
          a game-changer for the entire construction sector.
        </Typography>
        <Typography variant="body1">
          Building information modeling (BIM) is a model-based technology linked
          with a project information database. Simply put, with BIM technology,
          a building is constructed digitally. In this case, virtual models of
          each construction block as well as architectural, constructive, and
          MEP (mechanical, electrical, and plumbing) parts represent a single
          BIM model. The resulting computer-generated model possesses the
          precise geometry and information essential for assisting in the
          building's construction, fabrication, and acquisition processes.
          Therefore, let us attempt to ascertain BIM’s fundamental principles,
          essential components, and, without a doubt, its advantages.
        </Typography>
        <Typography variant="h2" color="blue">
          The technology is built on three pillars: 3D, Information, and
          Collaboration.
        </Typography>
        <Typography variant="body1">
          The technology is built on three pillars: 3D, Information, and
          Collaboration.
        </Typography>
        <List>
          <li>
            <strong>{`3D Model`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              A three-dimensional model of a construction element is its visual
              representation. A BIM model is a 3D where each object "knows"
              which construct it is and which one it is associated with.
            </Typography>
          </li>
          <li>
            <strong>{`Information Management`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Parameters control the behavior of an element. In the parameters,
              one can change the properties, thereby managing the information on
              the project.
            </Typography>
          </li>
          <li>
            <strong>{`Collaboration`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Collaboration between designers by linking model files in the
              Revit model for construction, as well as between the design office
              and the construction site by sending remarks via a mobile
              application with a photo and a comment attached to a model
              element. Both were carried out in the Common Data Environment
              (CDE).
            </Typography>
          </li>
        </List>
        <Typography variant="body1">
          World construction practice is abounding in errors that led to huge
          costs and unfinished construction, as well as examples of incorrect
          reading of drawings by builders due to a lack of information. A
          three-dimensional representation of the adjoining structures and
          nodes, together with user-friendly applications for collaboration
          between project members and information management, would help avoid
          ridiculous errors in design and construction, saving time and money.
          Furthermore, in addition to the simulations conducted on the model,
          such as the building's shape, size and volume, orientation, insulation
          levels, window sizes, and airtightness factors, there would be no
          glaring failures such as leaning towers, melting glass skyscrapers,
          and incomplete tunnels and airports.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/what-is-bim/image-1.png"
            alt="Trust me, I am an engineer"
            title="Trust me, I am an engineer"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig. 1 Trust me, I am an engineer
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Critical Ingredients for applying BIM technology
        </Typography>
        <List>
          <li>
            <strong>{`BIM software for construction`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The basic model development software must have the functionality
              of the above three criteria. This also applies to Mobile Apps for
              Construction (for example, SIGNAX BIM software for mobile).
            </Typography>
          </li>
          <li>
            <strong>{`BIM specialists`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              These are professionals with experience in design and
              construction, thinking three-dimensionally, proactive, and
              trained. Moreover, using BIM technology, it is possible to set up
              work remotely, considering a dispersed team. Therefore,
              enterprises possess a significant prospect of attracting highly
              skilled specialists to their teams, irrespective of their
              geographical location.
            </Typography>
          </li>
          <li>
            <strong>{`New procedures`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Incorporating BIM roles in the company, setting up a CDE,
              configuring the issuance of the model to the construction site,
              and digital document management linked to the model during the
              construction.
            </Typography>
          </li>
        </List>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/what-is-bim/image-2.png"
            alt="SIGNAX BIM software for mobile"
            title="SIGNAX BIM software for mobile"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig. 2 SIGNAX BIM software for mobile
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Benefits
        </Typography>
        <List>
          <li>
            <strong>{`Early Construction Cost Control.`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              BIM allows developers to not only build the most daring
              architectural ideas, such as biometric structures with complicated
              facades and interiors or high-rise buildings, but also
              automatically calculate volumes in the model at an early stage, so
              they can find a more optimal solution.
            </Typography>
          </li>
          <li>
            <strong>{`Increased project and Construction Collaboration.`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              It is already the norm for companies using BIM technology to hold
              general meetings with a demonstration of the model on a large
              screen. The majority of the challenging technical issues are
              eliminated by such working sessions before the project is
              officially launched for work. And builders are already beginning
              to appreciate the ease of reading design decisions thanks to 3D.
              Such a visual analysis of the model is possible using a tablet and
              Cloud-based Construction Software services that have all the
              necessary functions.
            </Typography>
          </li>
          <li>
            <strong>Improved decision-making</strong>
            {` in sustainable design and construction practices.`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Such issues as poor access to above-ceiling components and
              insufficient space to allow the full thickness of duct insulation
              can be eliminated using the existing collaboration functions in
              BIM software. And the issue of using outdated blueprints is not
              present when using a BIM model on a construction site.
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The use of space between building services (like plumbing and
              ventilation) and avoiding mistakes during construction is enhanced
              by automated checking collision clash detection (Fig. 3).
              Furthermore, virtual walk-throughs of a BIM model can help with
              the decision-making process of how to best use the space.
            </Typography>
          </li>
          <li>
            <strong>{`Reinforced construction and project management.`}</strong>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Construction sites are an environment where multiple activities
              are occurring simultaneously, resulting in another huge problem -
              tasks that are out of order. Here, a BIM model can be employed to
              generate 4D simulations that depict the construction process of
              the building over a period of time. As a result, a smooth workflow
              and the avoidance of delays or accidents are ensured by properly
              managing the construction site logistics.
            </Typography>
          </li>
          <li>
            <strong>The foundation for Ongoing Facilities Management:</strong>
            {` Complete BIM models help manage buildings, making them much easier to renovate in the future.`}
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The challenge of improving and standardizing the quality of the
              information Facility Managers have to face is constantly faced.
              BIM is a digital repository for all building components, and as a
              full 3D model, it can show views with a clarity that users usually
              don't get from standard 2D building drawings.
            </Typography>
          </li>
        </List>
        <div className={s.imageWrap}>
          <div className="flex">
            <StaticImage
              width={900}
              src="../assets/images/articles/what-is-bim/image-3.png"
              alt="Advanced clash Detection in SIGNAX Tools and Autodesk Navisworks"
              title="Advanced clash Detection in SIGNAX Tools and Autodesk Navisworks"
              placeholder="blurred"
            />
            <StaticImage
              src="../assets/images/articles/what-is-bim/image-4.png"
              alt="Advanced clash Detection in SIGNAX Tools and Autodesk Navisworks"
              title="Advanced clash Detection in SIGNAX Tools and Autodesk Navisworks"
              placeholder="blurred"
            />
          </div>
          <Typography variant="body2" color="gray">
            Fig. 3 Advanced clash Detection in SIGNAX Tools and Autodesk
            Navisworks
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Results
        </Typography>
        <Typography variant="body1">
          The field of Building Information Modeling is regarded as one of the
          most promising advancements in the architecture, engineering, and
          construction (AEC) industries. The construction industry has now
          experienced the significant outcomes of this relatively new
          technology. Let us list the fundamental ones:
        </Typography>
        <List>
          <li>Reduces construction time and cost;</li>
          <li>Enhanced quality of the project and an overall better build;</li>
          <li>Preventing accidents on the construction site.</li>
        </List>
        <Typography variant="body1">
          And that should be enough to understand that BIM approach is the point
          of no return, after which it is unlikely that anyone will return to 2D
          CAD. As is the case now, no one imagines the execution of drawings on
          a drawing board. Using BIM models for buildings and infrastructure is
          better than using traditional methods. This means there are fewer
          mistakes and rework, and it saves money and time.
        </Typography>
        <Typography variant="body1">
          To summarize, BIM is not only about software and 3D. It is also about
          the way of thinking, which saves time. Lost time is the most costly
          thing in construction. Among other products on the market, SIGNAX is
          designed to help you reduce the time needed to make the correct
          management decision. The construction industry will then be able to
          produce high-quality objects.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/what-is-bim/image-5.png"
            alt="Completed BIM model is a digital repository"
            title="Completed BIM model is a digital repository"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig 4. Completed BIM model is a digital repository.
          </Typography>
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default WhatIsBim
